@use './mixins' as *;
@use './variables' as *;

// Por defecto la config del botón es Medium
.au-split-button {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include set-config(map-get($btn-primary, idle));
  @include set-config(map-get($btn-split, md));
  border-radius: map-get($btn-split, border-radius);
  transition: box-shadow 200ms ease-in-out, background-color 200ms ease-in-out;
  user-select: none;

  &:hover {
    @include set-config(map-get($btn-primary, hover));
  }

  &[disabled],
  &:disabled {
    @include set-config(map-get($btn-primary, disabled));
  }

  &--error {
    @include set-config(map-get($btn-error, idle));

    &:hover {
      @include set-config(map-get($btn-error, hover));
    }

    &[disabled],
    &:disabled {
      @include set-config(map-get($btn-error, disabled));
    }
    .au-split-button__main-action,
    .au-split-button__secondary-action {
      &:hover,
      &:focus,
      &:active {
        background-color: var(--au-error-700);
      }
    }

    .au-split-button__divider {
      background-color: var(--au-error-700);
    }
  }

  &__main-action {
    display: flex;
    align-items: center;
    height: 100%;
    @include set-config(map-get($main-action, md));
    transition: background-color 200ms ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--au-primary-700);
    }
  }

  &__secondary-action {
    display: flex;
    align-items: center;
    height: 100%;
    @include set-config(map-get($secondary-action, md));
    transition: background-color 200ms ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--au-primary-700);
    }
  }

  &__divider {
    background-color: map-get($divider, background-color);
    width: map-get($divider, width);
    @include set-config(map-get($divider, md));
  }

  &--sm {
    @include set-config(map-get($btn-split, sm));

    .au-split-button__main-action {
      @include set-config(map-get($main-action, sm));
    }

    .au-split-button__secondary-action {
      @include set-config(map-get($secondary-action, sm));
    }

    .au-split-button__divider {
      @include set-config(map-get($divider, sm));
    }
  }

  &--lg {
    @include set-config(map-get($btn-split, lg));

    .au-split-button__main-action {
      @include set-config(map-get($main-action, lg));
    }

    .au-split-button__secondary-action {
      @include set-config(map-get($secondary-action, lg));
    }

    .au-split-button__divider {
      @include set-config(map-get($divider, lg));
    }
  }
}
