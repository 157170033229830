.au-dialog {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  &__main {
    align-items: center;
    background: var(--au-neutral-0, #ffffff);
    border-radius: 8px;
    display: inline-flex;
    flex-direction: column;
    gap: var(--au-spacer-24, 24px);
    width: 100%;
    padding: var(--au-spacer-40, 40px) var(--au-spacer-32, 32px);
    position: relative;
    z-index: 1;
  }

  &__body {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: var(--au-spacer-16, 16px);
    text-align: center;
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: var(--au-spacer-8, 8px);
    place-content: center;
  }

  &__close {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 40px;
  }

  &__backdrop {
    backdrop-filter: blur(3px);
    background-color: var(--au-neutral-800, #535765);
    height: 100%;
    left: 0;
    opacity: 0.48;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    &.is-dismissable {
      pointer-events: all;
    }
  }
}
