@use './mixins' as *;
@use './variables' as *;

// Por defecto la config del botón es Medium
.au-dropdown-button {
  position: relative;
  @include set-config(map-get($btn-dropdown, md));

  &__main-action {
    align-items: center;
    border-radius: 60px;
    display: flex;
    gap: 4px;
    height: 100%;
    min-width: auto;
    transition: background-color 200ms ease-in-out;
    @include set-config(map-get($main-action, md));
  }

  &__icon {
    font-size: var(--au-size-24, 24px);
  }

  &--sm {
    @include set-config(map-get($btn-dropdown, sm));

    .au-dropdown-button__main-action {
      @include set-config(map-get($main-action, sm));
    }
  }

  &--lg {
    @include set-config(map-get($btn-dropdown, lg));

    .au-dropdown-button__main-action {
      @include set-config(map-get($main-action, lg));
    }
  }
}
