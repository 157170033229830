// Default size: Medium

.c-feedback-icon {
  display: flex;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 64px;

  &__img {
    width: 100%;
    height: 100%;
  }

  &--sm {
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    border-radius: 48px;
  }

  &--lg {
    width: 6rem;
    height: 6rem;
    padding: 1.5rem;
    border-radius: 96px;
  }

  &--xl {
    width: 8rem;
    height: 8rem;
    padding: 2rem;
    border-radius: 128px;
  }

  &--au-error {
    background-color: var(--au-error-100);
    .c-feedback-icon__img {
      color: var(--au-error-600);
    }
  }

  &--danger {
    background-color: var(--au-error-100);
    .c-feedback-icon__img {
      color: var(--au-error-500);
    }
  }

  &--au-success {
    background-color: var(--au-success-100);
    .c-feedback-icon__img {
      color: var(--au-success-500);
    }
  }

  &--au-warning {
    background-color: var(--au-warning-100);
    .c-feedback-icon__img {
      color: var(--au-warning-500);
    }
  }

  &--info {
    background-color: var(--au-primary-100);
    .c-feedback-icon__img {
      color: var(--au-primary-500);
    }
  }

  &--help {
    background-color: var(--au-primary-100);
    .c-feedback-icon__img {
      color: var(--au-primary-500);
    }
  }
}
