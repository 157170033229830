.divider {
  display: block;
  height: 1px;
  background-color: var(--au-neutral-300);

  &__dark {
    background-color: var(--au-neutral-400);
  }

  &__bold {
    height: 2px;
  }
}
