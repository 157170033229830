.au-shadow {
  &--100 {
    box-shadow: var(--au-shadow-100);
  }
  &--200 {
    box-shadow: var(--au-shadow-200);
  }
  &--300 {
    box-shadow: var(--au-shadow-300);
  }
  &--400 {
    box-shadow: var(--au-shadow-400);
  }
  &--500 {
    box-shadow: var(--au-shadow-500);
  }
  &--blue-100 {
    box-shadow: var(--au-shadow-blue-100);
  }
  &--blue-200 {
    box-shadow: var(--au-shadow-blue-200);
  }
  &--blue-300 {
    box-shadow: var(--au-shadow-blue-300);
  }
}
