.c-input {
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: var(--au-neutral-500);
  }

  &:disabled {
    background-color: #f2f2f2;
    color: #888;
    cursor: not-allowed;
    opacity: 0.7;
  }

  &__prefix,
  &__sufix {
    align-items: center;
    color: var(--au-neutral-700);
    display: none;
    font-size: var(--au-size-16);
    line-height: var(--au-size-18);
    margin: auto;
    padding-inline: 10px;
    pointer-events: none;
    position: absolute;
    transition: 0.2s ease all;
    white-space: nowrap;

    &.is-visible {
      display: flex;
    }

    &.is-in-bottom {
      align-items: flex-end;
    }

    &--sm {
      height: 48px;
      &.is-in-bottom {
        padding-bottom: 7px;
      }
    }

    &--md {
      height: 56px;
      &.is-in-bottom {
        padding-bottom: 9px;
      }
    }

    &--lg {
      height: 64px;
      &.is-in-bottom {
        padding-bottom: 12px;
      }
    }
  }

  &__sufix {
    right: 8px;
  }

  &__label {
    align-items: center;
    bottom: 0;
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    left: 12px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    text-decoration: none;
    text-overflow: ellipsis;
    top: 0;
    transition: 0.2s ease all;
    white-space: nowrap;
    width: calc(100% - 26px);
  }

  &__text {
    align-items: center;
    align-self: stretch;
    background: var(--au-neutral-0);
    border-radius: 4px;
    border: none;
    color: var(--au-neutral-900);
    font-size: var(--au-size-14);
    gap: 12px;
    line-height: 18px;
    outline: solid 1px var(--au-neutral-600);
    overflow: hidden;
    padding: 9px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &--mobile {
      outline: none;
    }

    &:focus {
      outline-width: 2.6px;
    }

    &.is-warning {
      outline-color: var(--au-warning-600);
    }

    &.is-success {
      outline-color: var(--au-success-600);
    }

    &.is-error {
      outline-color: var(--au-error-600);
    }

    &.is-active {
      outline-color: var(--au-neutral-600);
    }
  }

  &--sm {
    font-size: var(--au-size-14);
    height: 48px;
    padding: 6px 12px;
  }

  &--md {
    font-size: var(--au-size-16);
    height: 56px;
    padding: 9px 12px;
  }

  &--lg {
    font-size: var(--au-size-16);
    height: 64px;
    padding: 12px;
  }
}

/* Floating Label */
.c-float-label {
  display: block;
  font-size: var(--au-size-14);

  .c-input__text {
    padding-bottom: 8px;
  }

  &--sm {
    font-size: var(--au-size-12);
  }

  &--md,
  &--lg {
    font-size: var(--au-size-14);
  }

  & .c-input {
    font-size: var(--au-size-16);
    height: unset;

    &:focus ~ label,
    &.c-filled ~ label {
      font-style: normal;
      font-weight: 400;
    }

    &--sm {
      padding: 23px 12px 7px 12px;
      &:focus ~ label,
      &.c-filled ~ label {
        font-size: 12px;
        line-height: 14px;
        transform: translateY(-9px);
      }
    }

    &--md {
      padding: 29px 12px 9px 12px;
      &:focus ~ label,
      &.c-filled ~ label {
        font-size: 14px;
        line-height: 16px;
        transform: translateY(-9px);
      }
    }

    &--lg {
      padding: 34px 12px 12px 12px;
      &:focus ~ label,
      &.c-filled ~ label {
        font-size: 14px;
        line-height: 16px;
        transform: translateY(-12px);
      }
    }
  }

  > label {
    align-items: center;
    bottom: 0;
    color: var(--au-neutral-700);
    display: flex;
    font-size: 16px;
    left: 12px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    text-decoration: none;
    text-overflow: ellipsis;
    top: 0;
    transition: 0.2s ease all;
    white-space: nowrap;
    width: calc(100% - 26px);
  }

  textarea ~ label {
    top: 1rem;
  }

  input:-webkit-autofill ~ label {
    font-style: normal;
    font-weight: 400;
    transform: translateY(-15px);
  }

  .c-placeholder,
  input::placeholder,
  & {
    opacity: 0;
    transition: all 0.2s ease;
  }

  .c-focus .c-placeholder,
  input:focus::placeholder,
  & {
    opacity: 1;
  }
}
