.au-text {
  &--display {
    &-xl {
      font-size: var(--au-size-56);
      line-height: var(--au-size-72);
    }

    &-lg {
      font-size: var(--au-size-40);
      line-height: var(--au-size-46);
    }

    &-md {
      font-size: var(--au-size-34);
      line-height: var(--au-size-38);
    }

    &-sm {
      font-size: var(--au-size-28);
      line-height: var(--au-size-32);
    }
  }

  &--title {
    &-lg {
      font-size: var(--au-size-26);
      line-height: var(--au-size-32);
    }

    &-md {
      font-size: var(--au-size-22);
      line-height: var(--au-size-28);
    }

    &-sm {
      font-size: var(--au-size-18);
      line-height: var(--au-size-26);
    }
  }

  &--body {
    &-lg {
      font-size: var(--au-size-16);
      line-height: var(--au-size-24);
    }

    &-md {
      font-size: var(--au-size-14);
      line-height: var(--au-size-22);
    }

    &-sm {
      font-size: var(--au-size-12);
      line-height: var(--au-size-20);
    }
  }

  &--label {
    &-lg {
      font-size: var(--au-size-16);
      line-height: var(--au-size-18);
    }

    &-md {
      font-size: var(--au-size-14);
      line-height: var(--au-size-16);
    }

    &-sm {
      font-size: var(--au-size-12);
      line-height: var(--au-size-14);
    }

    &-xs {
      font-size: var(--au-size-10);
      line-height: var(--au-size-12);
    }

    &-xxs {
      font-size: var(--au-size-8);
      line-height: var(--au-size-8);
    }
  }

  &--overline {
    &-lg {
      font-size: var(--au-size-14);
      line-height: var(--au-size-22);
    }

    &-md {
      font-size: var(--au-size-12);
      line-height: var(--au-size-18);
    }

    &-sm {
      font-size: var(--au-size-11);
      line-height: var(--au-size-14);
    }

    &-xs {
      font-size: var(--au-size-10);
      line-height: var(--au-size-10);
    }
  }

  &--caption {
    &-lg {
      font-size: var(--au-size-14);
      line-height: var(--au-size-22);
    }

    &-md {
      font-size: var(--au-size-12);
      line-height: var(--au-size-20);
    }
  }

  &--legal {
    &-lg {
      font-size: var(--au-size-12);
      line-height: var(--au-size-18);
    }

    &-md {
      font-size: var(--au-size-10);
      line-height: var(--au-size-16);
    }
  }

  &--actions {
    &-lg {
      font-size: var(--au-size-18);
      line-height: var(--au-size-48);
    }

    &-md {
      font-size: var(--au-size-16);
      line-height: var(--au-size-40);
    }

    &-sm {
      font-size: var(--au-size-14);
      line-height: var(--au-size-32);
    }

    &-xs {
      font-size: var(--au-size-10);
      line-height: var(--au-size-16);
    }
  }

  @mixin link-styles {
    color: var(--au-primary-600);
    text-decoration: underline;
  }
  &--links {
    @include link-styles;
    &-lg {
      font-size: var(--au-size-18);
      line-height: var(--au-size-48);
      @include link-styles;
    }

    &-md {
      font-size: var(--au-size-16);
      line-height: var(--au-size-40);
      @include link-styles;
    }

    &-sm {
      font-size: var(--au-size-14);
      line-height: var(--au-size-32);
      @include link-styles;
    }

    &-xs {
      font-size: var(--au-size-10);
      line-height: var(--au-size-16);
      @include link-styles;
    }
  }

  /* #region Genera las clases de color de textos */
  &--neutral-0 {
    color: var(--au-neutral-0);
  }

  &--neutral-1000-alpha-65 {
    color: var(--au-neutral-1000-alpha-65);
  }

  $values: '100', '200', '300', '400', '500', '600', '700', '800', '900', '1000';
  @each $value in $values {
    &--neutral-#{$value} {
      color: var(--au-neutral-#{$value});
    }

    &--primary-#{$value} {
      color: var(--au-primary-#{$value});
    }

    &--secondary-#{$value} {
      color: var(--au-secondary-#{$value});
    }

    &--error-#{$value} {
      color: var(--au-error-#{$value});
    }

    &--warning-#{$value} {
      color: var(--au-warning-#{$value});
    }

    &--success-#{$value} {
      color: var(--au-success-#{$value});
    }
  }
  /* #endregion Genera las clases de color de textos */
}

.au-text--light {
  font-weight: var(--au-font-light);
}

.au-text--regular {
  font-weight: var(--au-font-regular);
}

.au-text--medium {
  font-weight: var(--au-font-medium);
}

.au-text--bold {
  font-weight: var(--au-font-bold);
}

.au-text--no-wrap {
  white-space: nowrap;
}

.au-text--uppercase {
  text-transform: uppercase;
}

.au-text--all-sm-caps {
  font-variant: all-small-caps;
}
