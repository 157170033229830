.au-ripple {
  position: absolute;
  background-color: var(--au-neutral-0);
  border-radius: 50%;
  transform: scale(0);
  opacity: 0.15;
  transform: translate(-50%, -50%);
  animation: ripple-animation 410ms ease-in-out;

  &--neutral {
    background-color: var(--au-neutral-0);

    &-100 {
      background-color: var(--au-neutral-100);
    }
  }

  &--blue {
    &-500 {
      background-color: var(--au-primary-500);
    }
  }

  &--red {
    &-500 {
      background-color: var(--au-error-500);
    }
  }
}

@keyframes ripple-animation {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
