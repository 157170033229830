@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto'), local('Roboto-Light'), url('../assets/fonts/Roboto-Light.ttf') format('woff2'),
    url('../assets/fonts/Roboto-Light.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('../assets/fonts/Roboto-Regular.ttf') format('woff2'),
    url('../assets/fonts/Roboto-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: medium;
  font-weight: 500;
  src: local('Roboto'), local('Roboto-Medium'), url('../assets/fonts/Roboto-Medium.ttf') format('woff2'),
    url('../assets/fonts/Roboto-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 700;
  src: local('Roboto'), local('Roboto-Bold'), url('../assets/fonts/Roboto-Bold.ttf') format('woff2'),
    url('../assets/fonts/Roboto-Bold.ttf') format('woff');
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  outline: 0px solid;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    outline: 0px solid;
  }
}
