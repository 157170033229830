$br-xs: 375px;
$br-sm: 600px;
$br-md: 1366px;
$br-lg: 1920px;

@mixin xs() {
  @media (width < $br-xs) {
    @content;
  }
}

@mixin sm() {
  @media ($br-xs <= width) {
    @content;
  }
}

@mixin md() {
  @media ($br-sm <= width) {
    @content;
  }
}

@mixin lg() {
  @media ($br-md <= width) {
    @content;
  }
}

@mixin xl() {
  @media (width >= $br-lg) {
    @content;
  }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin tag-color-variant($color, $bg-color, $border-color) {
  color: $color;
  background-color: $bg-color;
  border: 0.6px solid $border-color;
}
