$gutter: var(--au-spacer-8) !default;

.au-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: calc(-1 * $gutter);
  margin-left: calc(-1 * $gutter);
  margin-top: calc(-1 * $gutter);
}

.au-grid > .au-col,
.au-grid > [class*='au-col'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.au-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.au-nogutter > .au-col,
.au-nogutter > [class*='au-col-'] {
  padding: 0;
}

.au-col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  padding: $gutter;
}

.au-col-fixed {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: $gutter;
}

.au-col-1,
.au-col-2,
.au-col-3,
.au-col-4,
.au-col-5,
.au-col-6,
.au-col-7,
.au-col-8,
.au-col-9,
.au-col-10,
.au-col-11,
.au-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: $gutter;
}

.au-col-1 {
  width: 8.3333%;
}

.au-col-2 {
  width: 16.6667%;
}

.au-col-3 {
  width: 25%;
}

.au-col-4 {
  width: 33.3333%;
}

.au-col-5 {
  width: 41.6667%;
}

.au-col-6 {
  width: 50%;
}

.au-col-7 {
  width: 58.3333%;
}

.au-col-8 {
  width: 66.6667%;
}

.au-col-9 {
  width: 75%;
}

.au-col-10 {
  width: 83.3333%;
}

.au-col-11 {
  width: 91.6667%;
}

.au-col-12 {
  width: 100%;
}

.au-offset-12 {
  margin-left: 100%;
}

.au-offset-11 {
  margin-left: 91.66666667%;
}

.au-offset-10 {
  margin-left: 83.33333333%;
}

.au-offset-9 {
  margin-left: 75%;
}

.au-offset-8 {
  margin-left: 66.66666667%;
}

.au-offset-7 {
  margin-left: 58.33333333%;
}

.au-offset-6 {
  margin-left: 50%;
}

.au-offset-5 {
  margin-left: 41.66666667%;
}

.au-offset-4 {
  margin-left: 33.33333333%;
}

.au-offset-3 {
  margin-left: 25%;
}

.au-offset-2 {
  margin-left: 16.66666667%;
}

.au-offset-1 {
  margin-left: 8.33333333%;
}

.au-offset-0 {
  margin-left: 0%;
}

.au-sm-1,
.au-sm-2,
.au-sm-3,
.au-sm-4,
.au-sm-5,
.au-sm-6,
.au-sm-7,
.au-sm-8,
.au-sm-9,
.au-sm-10,
.au-sm-11,
.au-sm-12,
.au-md-1,
.au-md-2,
.au-md-3,
.au-md-4,
.au-md-5,
.au-md-6,
.au-md-7,
.au-md-8,
.au-md-9,
.au-md-10,
.au-md-11,
.au-md-12,
.au-lg-1,
.au-lg-2,
.au-lg-3,
.au-lg-4,
.au-lg-5,
.au-lg-6,
.au-lg-7,
.au-lg-8,
.au-lg-9,
.au-lg-10,
.au-lg-11,
.au-lg-12,
.au-xl-1,
.au-xl-2,
.au-xl-3,
.au-xl-4,
.au-xl-5,
.au-xl-6,
.au-xl-7,
.au-xl-8,
.au-xl-9,
.au-xl-10,
.au-xl-11,
.au-xl-12 {
  padding: $gutter;
}

.au-col-nogutter {
  padding: 0;
}

@include sm() {
  .au-sm-1,
  .au-sm-2,
  .au-sm-3,
  .au-sm-4,
  .au-sm-5,
  .au-sm-6,
  .au-sm-7,
  .au-sm-8,
  .au-sm-9,
  .au-sm-10,
  .au-sm-11,
  .au-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .au-sm-1 {
    width: 8.3333%;
  }

  .au-sm-2 {
    width: 16.6667%;
  }

  .au-sm-3 {
    width: 25%;
  }

  .au-sm-4 {
    width: 33.3333%;
  }

  .au-sm-5 {
    width: 41.6667%;
  }

  .au-sm-6 {
    width: 50%;
  }

  .au-sm-7 {
    width: 58.3333%;
  }

  .au-sm-8 {
    width: 66.6667%;
  }

  .au-sm-9 {
    width: 75%;
  }

  .au-sm-10 {
    width: 83.3333%;
  }

  .au-sm-11 {
    width: 91.6667%;
  }

  .au-sm-12 {
    width: 100%;
  }

  .au-sm-offset-12 {
    margin-left: 100%;
  }

  .au-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .au-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .au-sm-offset-9 {
    margin-left: 75%;
  }

  .au-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .au-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .au-sm-offset-6 {
    margin-left: 50%;
  }

  .au-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .au-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .au-sm-offset-3 {
    margin-left: 25%;
  }

  .au-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .au-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .au-sm-offset-0 {
    margin-left: 0%;
  }
}

@include md() {
  .au-md-1,
  .au-md-2,
  .au-md-3,
  .au-md-4,
  .au-md-5,
  .au-md-6,
  .au-md-7,
  .au-md-8,
  .au-md-9,
  .au-md-10,
  .au-md-11,
  .au-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .au-md-1 {
    width: 8.3333%;
  }

  .au-md-2 {
    width: 16.6667%;
  }

  .au-md-3 {
    width: 25%;
  }

  .au-md-4 {
    width: 33.3333%;
  }

  .au-md-5 {
    width: 41.6667%;
  }

  .au-md-6 {
    width: 50%;
  }

  .au-md-7 {
    width: 58.3333%;
  }

  .au-md-8 {
    width: 66.6667%;
  }

  .au-md-9 {
    width: 75%;
  }

  .au-md-10 {
    width: 83.3333%;
  }

  .au-md-11 {
    width: 91.6667%;
  }

  .au-md-12 {
    width: 100%;
  }

  .au-md-offset-12 {
    margin-left: 100%;
  }

  .au-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .au-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .au-md-offset-9 {
    margin-left: 75%;
  }

  .au-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .au-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .au-md-offset-6 {
    margin-left: 50%;
  }

  .au-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .au-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .au-md-offset-3 {
    margin-left: 25%;
  }

  .au-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .au-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .au-md-offset-0 {
    margin-left: 0%;
  }
}

@include lg() {
  .au-lg-1,
  .au-lg-2,
  .au-lg-3,
  .au-lg-4,
  .au-lg-5,
  .au-lg-6,
  .au-lg-7,
  .au-lg-8,
  .au-lg-9,
  .au-lg-10,
  .au-lg-11,
  .au-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .au-lg-1 {
    width: 8.3333%;
  }

  .au-lg-2 {
    width: 16.6667%;
  }

  .au-lg-3 {
    width: 25%;
  }

  .au-lg-4 {
    width: 33.3333%;
  }

  .au-lg-5 {
    width: 41.6667%;
  }

  .au-lg-6 {
    width: 50%;
  }

  .au-lg-7 {
    width: 58.3333%;
  }

  .au-lg-8 {
    width: 66.6667%;
  }

  .au-lg-9 {
    width: 75%;
  }

  .au-lg-10 {
    width: 83.3333%;
  }

  .au-lg-11 {
    width: 91.6667%;
  }

  .au-lg-12 {
    width: 100%;
  }

  .au-lg-offset-12 {
    margin-left: 100%;
  }

  .au-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .au-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .au-lg-offset-9 {
    margin-left: 75%;
  }

  .au-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .au-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .au-lg-offset-6 {
    margin-left: 50%;
  }

  .au-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .au-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .au-lg-offset-3 {
    margin-left: 25%;
  }

  .au-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .au-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .au-lg-offset-0 {
    margin-left: 0%;
  }
}

@include xl() {
  .au-xl-1,
  .au-xl-2,
  .au-xl-3,
  .au-xl-4,
  .au-xl-5,
  .au-xl-6,
  .au-xl-7,
  .au-xl-8,
  .au-xl-9,
  .au-xl-10,
  .au-xl-11,
  .au-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .au-xl-1 {
    width: 8.3333%;
  }

  .au-xl-2 {
    width: 16.6667%;
  }

  .au-xl-3 {
    width: 25%;
  }

  .au-xl-4 {
    width: 33.3333%;
  }

  .au-xl-5 {
    width: 41.6667%;
  }

  .au-xl-6 {
    width: 50%;
  }

  .au-xl-7 {
    width: 58.3333%;
  }

  .au-xl-8 {
    width: 66.6667%;
  }

  .au-xl-9 {
    width: 75%;
  }

  .au-xl-10 {
    width: 83.3333%;
  }

  .au-xl-11 {
    width: 91.6667%;
  }

  .au-xl-12 {
    width: 100%;
  }

  .au-xl-offset-12 {
    margin-left: 100%;
  }

  .au-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .au-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .au-xl-offset-9 {
    margin-left: 75%;
  }

  .au-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .au-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .au-xl-offset-6 {
    margin-left: 50%;
  }

  .au-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .au-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .au-xl-offset-3 {
    margin-left: 25%;
  }

  .au-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .au-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .au-xl-offset-0 {
    margin-left: 0%;
  }
}
