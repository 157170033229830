.textfield {
  &__control-message {
    color: var(--au-neutral-600);
    font-size: var(--au-size-12);
    font-weight: var(--au-shadow-400);
    font-family: var(--au-font-family);
    line-height: 14px;
    padding-top: 4px;
    &--warning {
      color: var(--au-warning-600);
    }

    &--success {
      color: var(--au-success-600);
    }

    &--error {
      color: var(--au-error-600);
    }

    &--active {
      color: var(--au-neutral-600);
    }
  }

  &__input-with-icon {
    position: relative;
    color: #777; /* Color del icono */
    .icon-left {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      &--sm {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }

      &--md {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }

      &--lg {
        height: 24px;
        width: 24px;
        font-size: 24px;
      }
    }
    .icon-right {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);

      &--cursor-pointer {
        cursor: pointer;
      }

      &--sm {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }

      &--md {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }

      &--lg {
        height: 24px;
        width: 24px;
        font-size: 24px;
      }
    }

    &--warning {
      color: var(--au-warning-600);
    }

    &--success {
      color: var(--au-success-600);
    }

    &--error {
      color: var(--au-error-600);
    }

    &--active {
      color: var(--au-neutral-600);
    }
  }
}
