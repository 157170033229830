@use './mixins' as *;
@use './variables' as *;

// Por defecto la config del icon botón es Medium
.au-icon-button {
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  transition: background-color 200ms ease-in-out;
  user-select: none;
  @include set-config(map-get($btn-size-config, md));

  &[disabled],
  &:disabled {
    opacity: 0.68;
    cursor: unset;
    pointer-events: none;
  }

  &--primary {
    @include set-config(map-get($btn-color, primary, state-idle));

    &:hover {
      @include set-config(map-get($btn-color, primary, state-hover));
    }

    &:active {
      @include set-config(map-get($btn-color, primary, state-active));
    }
  }

  &--danger {
    @include set-config(map-get($btn-color, danger, state-idle));

    &:hover {
      @include set-config(map-get($btn-color, danger, state-hover));
    }

    &:active {
      @include set-config(map-get($btn-color, danger, state-active));
    }
  }

  &--neutral {
    @include set-config(map-get($btn-color, neutral, state-idle));

    &:hover {
      @include set-config(map-get($btn-color, neutral, state-hover));
    }

    &:active {
      @include set-config(map-get($btn-color, neutral, state-active));
    }
  }

  &--sm {
    @include set-config(map-get($btn-size-config, sm));
  }
}
