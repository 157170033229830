// Default size lg
.au-option-list {
  background-color: var(--au-neutral-0);
  border-radius: 6px;
  box-shadow: 0px 1px 3px 1px #3333331a, 0px 1px 2px 0px #33333333;
  margin-block: 8px;
  padding-top: 8px;
  right: 0;
  top: 100%;
  height: fit-content;
  width: 216px;

  overflow-x: hidden;
  overflow-y: auto;
  user-select: none; /* Standard syntax */
  -ms-user-select: none; /* IE 10 and IE 11 */
  -webkit-user-select: none; /* Safari */

  &__group-title {
    display: flex;
    align-items: center;

    color: var(--au-neutral-800);
    font-size: var(--au-size-16);
    font-variant: all-small-caps;
    line-height: var(--au-size-18);
    padding: 12px 16px 8px 16px;
  }

  &__item {
    display: flex;
    align-items: center;

    color: var(--au-neutral-800);
    font-size: var(--au-size-16);
    gap: 12px;
    height: 56px;
    line-height: var(--au-size-18);
    padding: 16px 24px;

    cursor: pointer;
    transition: background-color 150ms ease-in-out;

    &:hover {
      background-color: var(--au-primary-100);
    }
  }

  &__icon {
    color: var(--au-neutral-800);
    font-size: var(--au-size-24);
  }

  &__divider {
    background-color: #dddddd;
    height: 1px;
    margin-block: 2px;
    width: 100%;
  }

  &--sm {
    & .au-option-list__item {
      height: 40px;
      padding: 8px 16px;
    }

    & .au-option-list__icon {
      font-size: var(--au-size-20);
    }
  }

  &--floating {
    position: absolute;
    z-index: 10;
  }

  /* Estilos del scrollbar */
  &::-webkit-scrollbar {
    width: 4px; /* Ancho del scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--au-neutral-700);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--au-neutral-300);
    border-radius: 2px;
  }
}
