html {
  scroll-behavior: smooth;
}

body,
:host {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: var(--au-font-family);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

img {
  max-width: 100%;
  height: auto;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
