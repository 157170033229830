:root,
:host {
  /*#region Colors */
  /* Neutral */
  --au-neutral-0: #ffffff;
  --au-neutral-100: #f6f6f7;
  --au-neutral-200: #eeeff1;
  --au-neutral-300: #dcdee2;
  --au-neutral-400: #b9bdc6;
  --au-neutral-500: #969ca9;
  --au-neutral-600: #85899b;
  --au-neutral-700: #6a6f81;
  --au-neutral-800: #535765;
  --au-neutral-900: #32353e;
  --au-neutral-1000: #17181c;
  --au-neutral-1000-alpha-65: #17181ca6;

  /* Primary */
  --au-primary-100: #f0f3ff;
  --au-primary-200: #ccdeff;
  --au-primary-300: #bfd3ff;
  --au-primary-400: #80a8ff;
  --au-primary-500: #5289ff;
  --au-primary-600: #2a53f6;
  --au-primary-700: #143fcc;
  --au-primary-800: #042a9d;
  --au-primary-900: #011b6f;
  --au-primary-1000: #010c32;

  /* Secondary */
  --au-secondary-100: #f5fcff;
  --au-secondary-200: #ccf0ff;
  --au-secondary-300: #9ae1fe;
  --au-secondary-400: #5dcefe;
  --au-secondary-500: #2cb7f2;
  --au-secondary-600: #029dde;
  --au-secondary-700: #027aac;
  --au-secondary-800: #016188;
  --au-secondary-900: #013951;
  --au-secondary-1000: #012332;

  /* Error */
  --au-error-100: #fff0f1;
  --au-error-200: #ffc6c9;
  --au-error-300: #ffb2b7;
  --au-error-400: #ff7e87;
  --au-error-500: #e85464;
  --au-error-600: #d12241;
  --au-error-700: #b50628;
  --au-error-800: #950924;
  --au-error-900: #650314;
  --au-error-1000: #390006;

  /* Warning */
  --au-warning-100: #fff9d3;
  --au-warning-200: #fbe698;
  --au-warning-300: #eec851;
  --au-warning-400: #d8ab2d;
  --au-warning-500: #c48a00;
  --au-warning-600: #9e6a00;
  --au-warning-700: #815100;
  --au-warning-800: #673f01;
  --au-warning-900: #4c2d00;
  --au-warning-1000: #382200;

  /* Success */
  --au-success-100: #dcf9e7;
  --au-success-200: #a9efc4;
  --au-success-300: #7cdaa3;
  --au-success-400: #52bc81;
  --au-success-500: #2d985e;
  --au-success-600: #207c4b;
  --au-success-700: #17663c;
  --au-success-800: #10512f;
  --au-success-900: #093d22;
  --au-success-1000: #042a15;
  /*#endregion Colors */

  /*#region Shadows*/
  --au-shadow-100: 0px 1px 3px 1px #6a6f8126, 0px 1px 2px 0px #6a6f8140;
  --au-shadow-200: 0px 2px 6px 2px #6a6f8126, 0px 1px 2px 0px #6a6f8140;
  --au-shadow-300: 0px 4px 8px 3px #6a6f8126, 0px 1px 3px 0px #6a6f8140;
  --au-shadow-400: 0px 6px 10px 4px #6a6f811a, 0px 2px 3px 0px #6a6f8133;
  --au-shadow-500: 0px 8px 12px 6px #6a6f811a, 0px 4px 4px 0px #6a6f8133;

  --au-shadow-blue-100: 0px 1px 3px 1px #214dff1a, 0px 1px 2px 0px #214dff33;
  --au-shadow-blue-200: 0px 2px 6px 2px #214dff1a, 0px 1px 2px 0px #214dff33;
  --au-shadow-blue-300: 0px 4px 8px 3px #214dff1a, 0px 1px 3px 0px #214dff33;
  /*#endregion Shadows */

  /*#region Font and typography */
  --au-font-family: 'Roboto';
  $sizes: 8, 10, 11, 12, 14, 16, 18, 20, 22, 24, 25, 26, 28, 30, 32, 34, 38, 40, 46, 48, 56, 72;
  @each $size in $sizes {
    --au-size-#{$size}: #{$size}px;
  }

  /* Font weight */
  --au-font-light: 300;
  --au-font-regular: 400;
  --au-font-medium: 500;
  --au-font-bold: 700;
  /*#endregion Font and typography */

  /*#region Spacers */
  $spacers-values: 4, 8, 12, 16, 24, 32, 40, 48;
  @each $value in $spacers-values {
    --au-spacer-#{$value}: #{$value}px;
  }
  /*#endregion Spacers */

  /*#region Breakpoints */
  --au-br-xs: 375px; /* Extra small */
  --au-br-sm: 600px; /* Small */
  --au-br-md: 1366px; /* Medium */
  --au-br-lg: 1920px; /* Large */
  /*#endregion Breakpoints */
}
