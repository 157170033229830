.au-calendar {
  align-items: center;
  background-color: var(--au-neutral-0, #ffffff);
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  min-width: 316px;
  padding: 16px 16px 24px 16px;

  &--supra {
    min-width: 633px;

    .au-calendar__title {
      pointer-events: none;
    }
  }

  &--floating {
    position: absolute;
    z-index: 1000;
    top: calc(100% + 9px);
    left: 0;

    &::before {
      background-color: transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0;
      border-bottom: 8px solid var(--au-neutral-0, #ffffff);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      left: 20px;
      top: -7px;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    border-radius: 4px;
    color: var(--au-neutral-900, #32353e);
    cursor: pointer;
    padding: 2px;
    transition: background-color 150ms ease-in-out;

    &:hover {
      background-color: var(--au-neutral-100, #f6f6f7);
    }
  }

  &__prev-button,
  &__next-button {
    align-items: center;
    color: var(--au-neutral-900, #32353e);
    cursor: pointer;
    display: flex;
    font-size: 26px;
    width: 26px;
  }

  &__supra-calendar-grid {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 32px;

    .au-calendar__grid-days {
      flex: 1;
    }
  }

  /* Grid de dias */
  &__grid-days {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
    user-select: none;
  }

  /* Fila de encabezado de la semana */
  &__week {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;

    /* Encabezado de la semana (Lun, Mar, ...) */
    &-day {
      color: var(--neutral-900, #32353e);
      text-align: center;
      width: 32px;
    }
  }

  /* Dias de la semana */
  &__days {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
  }

  &__day {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    position: relative;
    width: 32px;

    &.is-in-range {
      background-color: var(--au-primary-200, #ccdeff);
      border-radius: 6px;

      & .au-calendar__day-box {
        &:hover {
          background-color: transparent;
        }
      }

      &:not(:first-child):not(:last-child)::before {
        background-color: var(--au-primary-200, #ccdeff);
        content: '';
        height: 32px;
        left: -15px;
        position: absolute;
        right: -15px;
      }

      &:first-child::before {
        background-color: var(--au-primary-200, #ccdeff);
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
        content: '';
        height: 32px;
        left: 0px;
        position: absolute;
        right: -15px;
      }

      &:last-child::before {
        background-color: var(--au-primary-200, #ccdeff);
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        content: '';
        height: 32px;
        left: -15px;
        position: absolute;
        right: 0px;
      }
    }

    &-box {
      align-items: center;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      transition: background-color 150ms ease-in-out;
      width: 100%;
      z-index: 1;

      &:hover {
        background-color: var(--au-neutral-200, #eeeff1);
      }

      &.is-out-month {
        color: var(--au-neutral-700, #6a6f81);
        cursor: unset;
        pointer-events: none;
      }

      &.is-current-date {
        border: 1px solid var(--au-primary-400, #80a8ff);
        color: var(--au-primary-600, #2a53f6);
      }

      &.is-selected,
      &.is-start-date,
      &.is-end-date {
        background-color: var(--au-primary-600, #2a53f6);
        color: var(--au-neutral-0, #ffffff);
        z-index: 2;
      }

      &.is-start-date,
      &.is-end-date {
        &.is-out-month {
          background-color: var(--au-primary-200, #ccdeff);
          border-radius: 6px;
          color: var(--au-neutral-700, #6a6f81);
        }
      }
    }
  }

  &__month-selector,
  &__year-selector {
    display: grid;
    grid-template-columns: repeat(3, 72px);
    grid-template-rows: repeat(4, 32px);
    justify-content: space-between;
    row-gap: 32px;
    width: 100%;

    &-item {
      background-color: transparent;
      border-radius: 6px;
      border: none;
      color: var(--au-neutral-900, #32353e);
      cursor: pointer;
      font-weight: var(--au-font-regular, 400);
      transition: background-color 150ms ease-in-out;

      &:hover {
        background-color: var(--au-primary-100, #f0f3ff);
      }

      &.is-selected {
        background-color: var(--au-primary-600, #2a53f6);
        color: var(--au-neutral-0, #ffffff);
      }
    }
  }
}
