// Valores de margin y padding en píxeles, segun Foundations
$spacers-values: 4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96;

.au {
  &-m-auto {
    margin: auto;
  }
  &-mx-auto {
    margin-inline: auto;
  }
  &-my-auto {
    margin-block: auto;
  }
  &-ml-auto {
    margin-left: auto;
  }
  &-mr-auto {
    margin-right: auto;
  }
  &-mt-auto {
    margin-top: auto;
  }
  &-mb-auto {
    margin-bottom: auto;
  }

  &-p-auto {
    padding: auto;
  }
  &-px-auto {
    padding-inline: auto;
  }
  &-py-auto {
    padding-block: auto;
  }
  &-pt-auto {
    padding-top: auto;
  }
  &-pr-auto {
    padding-right: auto;
  }
  &-pb-auto {
    padding-bottom: auto;
  }
  &-pl-auto {
    padding-left: auto;
  }
}

@each $spacer-value in $spacers-values {
  .au-m-#{$spacer-value} {
    margin: #{$spacer-value}px;
  }
  .au-mx-#{$spacer-value} {
    margin-inline: #{$spacer-value}px;
  }
  .au-my-#{$spacer-value} {
    margin-block: #{$spacer-value}px;
  }
  .au-mt-#{$spacer-value} {
    margin-top: #{$spacer-value}px;
  }
  .au-mr-#{$spacer-value} {
    margin-right: #{$spacer-value}px;
  }
  .au-mb-#{$spacer-value} {
    margin-bottom: #{$spacer-value}px;
  }
  .au-ml-#{$spacer-value} {
    margin-left: #{$spacer-value}px;
  }
}

@each $spacer-value in $spacers-values {
  .au-p-#{$spacer-value} {
    padding: #{$spacer-value}px;
  }
  .au-px-#{$spacer-value} {
    padding-inline: #{$spacer-value}px;
  }
  .au-py-#{$spacer-value} {
    padding-block: #{$spacer-value}px;
  }
  .au-pt-#{$spacer-value} {
    padding-top: #{$spacer-value}px;
  }
  .au-pr-#{$spacer-value} {
    padding-right: #{$spacer-value}px;
  }
  .au-pb-#{$spacer-value} {
    padding-bottom: #{$spacer-value}px;
  }
  .au-pl-#{$spacer-value} {
    padding-left: #{$spacer-value}px;
  }
}
