/* You can add global styles to this file, and also import other style files */
@import './assets/sass/ma-theme.scss';
body {
  background-color: #fff;
}

.contenedor-card {
  display: flex;
}

.titulo-card {
  text-align: center;
  color: $colorTextoNeutro900;
}

.subtitulo-card {
  text-align: center;
  color: $colorTextoNeutro700;
}
