.au-datepicker {
  cursor: pointer;
  display: flex;
  gap: 4px;
  height: fit-content;
  position: relative;

  &__input {
    min-width: 316px;
    pointer-events: none;
  }
}
