@use '../au-mixins/styles' as *;

.au-modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  &__main {
    align-items: center;
    background: var(--au-neutral-0, #ffffff);
    border-radius: 6px;
    display: inline-flex;
    flex-direction: column;
    margin-inline: var(--au-spacer-24, 24px);
    max-height: 624px;
    max-width: 960px;
    min-width: 280px;
    position: relative;
    width: 100%;
    z-index: 1;

    &.is-full-page {
      border-radius: unset;
      height: 100%;
      justify-content: space-between;
      margin-inline: unset;
      max-height: 100dvh;
      max-width: 100dvw;

      .au-modal__content {
        height: 100%;
        max-height: unset;
        width: 100%;
      }
    }
  }

  &__header {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 32px 64px 32px 32px;
  }

  &__content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    max-height: 428px;
    overflow-y: auto;
  }

  &__footer {
    align-items: stretch;
    align-self: stretch;
    border-top: 1px solid var(--au-neutral-300, #dcdee2);
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
    justify-content: flex-end;
    padding: 24px 32px;

    @include md() {
      align-items: flex-end;
      flex-direction: row;
      min-height: 88px;
      :first-child {
        margin-right: auto;
      }
    }
  }

  &__close {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;
  }

  &__backdrop {
    backdrop-filter: blur(3px);
    background-color: var(--au-neutral-800, #535765);
    height: 100%;
    left: 0;
    opacity: 0.48;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    &.is-dismissable {
      pointer-events: all;
    }
  }
}
