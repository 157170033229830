.au-flex {
  display: flex;

  &--wrap {
    flex-wrap: wrap;
  }

  &--wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &--ai-center {
    align-items: center;
  }

  &--jc-center {
    justify-content: center;
  }

  &--jc-between {
    justify-content: space-between;
  }

  &--jc-around {
    justify-content: space-around;
  }

  &--jc-evenly {
    justify-content: space-evenly;
  }

  &--ai-start {
    align-items: flex-start;
  }

  &--ai-end {
    align-items: flex-end;
  }

  &--ai-baseline {
    align-items: baseline;
  }

  &--jc-start {
    justify-content: flex-start;
  }

  &--jc-end {
    justify-content: flex-end;
  }

  &--fd-row {
    flex-direction: row;
  }

  &--fd-row-reverse {
    flex-direction: row-reverse;
  }

  &--fd-column {
    flex-direction: column;
  }

  &--fd-column-reverse {
    flex-direction: column-reverse;
  }

  &--ac-start {
    align-content: flex-start;
  }

  &--ac-end {
    align-content: flex-end;
  }

  &--ac-center {
    align-content: center;
  }

  &--ac-between {
    align-content: space-between;
  }

  &--ac-around {
    align-content: space-around;
  }

  &--as-start {
    align-self: flex-start;
  }

  &--as-end {
    align-self: flex-end;
  }

  &--as-center {
    align-self: center;
  }

  &--as-baseline {
    align-self: baseline;
  }
}
