@use './mixins' as *;
@use './variables' as *;

// Por defecto la config del botón es Medium
.au-button {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include set-config(map-get($btn-size-config, md));
  @include set-config(map-get($btn-color, primary, state-idle));
  gap: map-get($btn-common-config, gap);
  border-radius: map-get($btn-common-config, border-radius);
  white-space: nowrap;
  overflow: hidden;
  transition: box-shadow 200ms ease-in-out, background-color 200ms ease-in-out;
  user-select: none;

  &__spinner {
    border-radius: 50%;
    @include set-config(map-get($btn-color, primary, state-loading));
    @include set-config(map-get($btn-spinner-size, md, no-text));
    animation: rotation 1.5s linear infinite;

    &--with-text {
      @include set-config(map-get($btn-spinner-size, md, with-text));
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__icon {
    font-size: 24px;
    line-height: inherit;

    &--right {
      flex-direction: row-reverse;
    }
  }

  &:hover {
    @include set-config(map-get($btn-color, primary, state-hover));
  }

  &[disabled],
  &:disabled {
    @include set-config(map-get($btn-common-config, disabled));
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  &--sm {
    @include set-config(map-get($btn-size-config, sm));

    & .au-button__spinner {
      @include set-config(map-get($btn-spinner-size, sm, no-text));

      &--with-text {
        @include set-config(map-get($btn-spinner-size, sm, with-text));
      }
    }
  }

  &--lg {
    @include set-config(map-get($btn-size-config, lg));

    & .au-button__spinner {
      @include set-config(map-get($btn-spinner-size, lg, no-text));

      &--with-text {
        @include set-config(map-get($btn-spinner-size, lg, with-text));
      }
    }
  }

  &--danger {
    @include set-config(map-get($btn-color, danger, state-idle));

    & .au-button__spinner {
      @include set-config(map-get($btn-color, danger, state-loading));
    }

    &:hover {
      @include set-config(map-get($btn-color, danger, state-hover));
    }
  }

  &--outlined {
    @include set-config(map-get($btn-outlined, primary, state-idle));

    &:hover {
      @include set-config(map-get($btn-outlined, primary, state-hover));
    }

    & .au-button__spinner {
      @include set-config(map-get($btn-outlined, primary, state-loading));
    }

    &.au-button--danger {
      @include set-config(map-get($btn-outlined, danger, state-idle));

      & .au-button__spinner {
        @include set-config(map-get($btn-outlined, danger, state-loading));
      }

      &:hover {
        @include set-config(map-get($btn-outlined, danger, state-hover));
      }
    }
  }

  &--link {
    @include set-config(map-get($btn-link, primary, state-idle));

    &:hover {
      @include set-config(map-get($btn-link, primary, state-hover));
    }

    & .au-button__spinner {
      @include set-config(map-get($btn-link, primary, state-loading));
    }

    &.au-button--danger {
      @include set-config(map-get($btn-link, danger, state-idle));

      & .au-button__spinner {
        @include set-config(map-get($btn-link, danger, state-loading));
      }

      &:hover {
        @include set-config(map-get($btn-link, danger, state-hover));
      }
    }
  }

  &--squared {
    border-radius: unset;
  }

  &--full-width {
    width: 100%;
  }

  &--fit-content {
    min-width: unset;
  }
}
