$card-elevation-default: 0 2px 4px rgba(0, 0, 0, 0.08);
$card-elevation-hover: 0 8px 16px rgba(0, 0, 0, 0.12), 0 -4px 16px -4px rgba(0, 0, 0, 0.08);
$card-transition: 200ms;

.c-card {
  background-color: var(--au-neutral-100);
  border: 1px solid rgba(232, 232, 232, 0.7);
  border-radius: 16px;
  box-shadow: $card-elevation-default;
  position: relative;
  transition: all $card-transition ease-in-out;
  width: 100%;

  &--hover:hover {
    box-shadow: $card-elevation-hover;
    cursor: pointer;
  }

  &--ripple {
    overflow: hidden;
  }
}
