@font-face {
  font-family: 'au-icons';
  src: url('../assets/fonts/au-icons.eot?pkwzlh');
  src: url('../assets/fonts/au-icons.eot?pkwzlh#iefix') format('embedded-opentype'),
    url('../assets/fonts/au-icons.ttf?pkwzlh') format('truetype'),
    url('../assets/fonts/au-icons.woff?pkwzlh') format('woff'),
    url('../assets/fonts/au-icons.svg?pkwzlh#au-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='aui-'],
[class*='aui-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'au-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aui-reset:before {
  content: '\e900';
}

.aui-warning-1:before {
  content: '\e901';
}

.aui-add:before {
  content: '\e902';
}

.aui-arrow-down:before {
  content: '\e903';
}

.aui-arrow-left:before {
  content: '\e904';
}

.aui-arrow-right:before {
  content: '\e905';
}

.aui-arrow-up:before {
  content: '\e906';
}

.aui-bin:before {
  content: '\e907';
}

.aui-calendar:before {
  content: '\e908';
}

.aui-cam:before {
  content: '\e909';
}

.aui-cash:before {
  content: '\e90a';
}

.aui-chat:before {
  content: '\e90b';
}

.aui-check:before {
  content: '\e90c';
}

.aui-clip:before {
  content: '\e90d';
}

.aui-close:before {
  content: '\e90e';
}

.aui-cobertura:before {
  content: '\e90f';
}

.aui-contact:before {
  content: '\e910';
}

.aui-copy:before {
  content: '\e911';
}

.aui-credit-card:before {
  content: '\e912';
}

.aui-cross:before {
  content: '\e913';
}

.aui-danger:before {
  content: '\e914';
}

.aui-debit-card:before {
  content: '\e915';
}

.aui-denuncia:before {
  content: '\e916';
}

.aui-desktop:before {
  content: '\e917';
}

.aui-document:before {
  content: '\e918';
}

.aui-download:before {
  content: '\e919';
}

.aui-edit:before {
  content: '\e91a';
}

.aui-external-link-down:before {
  content: '\e91b';
}

.aui-external-link-up:before {
  content: '\e91c';
}

.aui-filter-add:before {
  content: '\e91d';
}

.aui-filter-cancel:before {
  content: '\e91e';
}

.aui-filter-check:before {
  content: '\e91f';
}

.aui-filter-minus:before {
  content: '\e920';
}

.aui-filter:before {
  content: '\e921';
}

.aui-group:before {
  content: '\e922';
}

.aui-headphones:before {
  content: '\e923';
}

.aui-help:before {
  content: '\e924';
}

.aui-history:before {
  content: '\e925';
}

.aui-home:before {
  content: '\e926';
}

.aui-house:before {
  content: '\e927';
}

.aui-id-card:before {
  content: '\e928';
}

.aui-id:before {
  content: '\e929';
}

.aui-image:before {
  content: '\e92a';
}

.aui-information:before {
  content: '\e92b';
}

.aui-insecurity:before {
  content: '\e92c';
}

.aui-location:before {
  content: '\e92d';
}

.aui-log-in:before {
  content: '\e92e';
}

.aui-log-out:before {
  content: '\e92f';
}

.aui-love:before {
  content: '\e930';
}

.aui-mail:before {
  content: '\e931';
}

.aui-medio-de-pago:before {
  content: '\e932';
}

.aui-menu:before {
  content: '\e933';
}

.aui-minus-1:before {
  content: '\e934';
}

.aui-minus:before {
  content: '\e935';
}

.aui-mobile:before {
  content: '\e936';
}

.aui-no-signal:before {
  content: '\e937';
}

.aui-no-visible:before {
  content: '\e938';
}

.aui-notification:before {
  content: '\e939';
}

.aui-options:before {
  content: '\e93a';
}

.aui-phone:before {
  content: '\e93b';
}

.aui-plus:before {
  content: '\e93c';
}

.aui-price:before {
  content: '\e93d';
}

.aui-problem:before {
  content: '\e93e';
}

.aui-save:before {
  content: '\e93f';
}

.aui-scan:before {
  content: '\e940';
}

.aui-schedule:before {
  content: '\e941';
}

.aui-search:before {
  content: '\e942';
}

.aui-security:before {
  content: '\e943';
}

.aui-settings:before {
  content: '\e944';
}

.aui-share:before {
  content: '\e945';
}

.aui-chevron-down:before {
  content: '\e946';
}

.aui-chevron-left:before {
  content: '\e947';
}

.aui-chevron-right:before {
  content: '\e948';
}

.aui-chevron-up:before {
  content: '\e949';
}

.aui-siniestro:before {
  content: '\e94a';
}

.aui-solo-info:before {
  content: '\e94b';
}

.aui-solo-warning:before {
  content: '\e94c';
}

.aui-star:before {
  content: '\e94d';
}

.aui-swap:before {
  content: '\e94e';
}

.aui-tablet:before {
  content: '\e94f';
}

.aui-tag:before {
  content: '\e950';
}

.aui-team:before {
  content: '\e951';
}

.aui-ticket:before {
  content: '\e952';
}

.aui-time:before {
  content: '\e953';
}

.aui-upload:before {
  content: '\e954';
}

.aui-user:before {
  content: '\e955';
}

.aui-valid:before {
  content: '\e956';
}

.aui-visible:before {
  content: '\e957';
}

.aui-warning:before {
  content: '\e958';
}

.aui-wifi:before {
  content: '\e959';
}

.aui-work:before {
  content: '\e95a';
}
