// Neutral tooltip color
$bg-neutral: var(--au-neutral-900, #32353e);
$text-neutral: var(--au-neutral-200, #eeeff1);
// Primary tooltip color
$bg-primary: var(--au-primary-900, #011b6f);
$text-primary: var(--au-neutrlal-0, #ffffff);
// Warning tooltip color
$bg-warning: var(--au-warning-900, #4c2d00);
$text-warning: var(--au-warning-200, #fbe698);

.au-tooltip {
  border-radius: 6px;
  display: grid;
  opacity: 0;
  position: absolute;
  width: auto;

  &--neutral {
    background-color: $bg-neutral;
    border-color: $bg-neutral;
    color: $text-neutral;

    .au-tooltip__arrow {
      color: $bg-neutral;
    }
  }

  &--primary {
    background-color: $bg-primary;
    border-color: $bg-primary;
    color: $text-primary;

    .au-tooltip__arrow {
      color: $bg-primary;
    }
  }

  &--warning {
    background-color: $bg-warning;
    border-color: $bg-warning;
    color: $text-warning;

    .au-tooltip__arrow {
      color: $bg-warning;
    }
  }

  &__information {
    align-items: flex-start;
    display: flex;
    width: 100%;
    z-index: 1;

    &-icon {
      border-radius: 30px;
      display: flex;
      font-size: 30px;
      height: 30px;
      margin: 12px 0px 0px 12px;
      width: 30px;
    }

    &-content {
      padding: 10px 16px 10px 12px;
      flex-direction: column;
      display: flex;
    }
  }

  &__close {
    padding: 6px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: auto;
  }

  &__action-links {
    align-self: stretch;
    cursor: pointer;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding: 4px 12px 10px 0px;
    user-select: none;
  }

  &__arrow {
    background-color: transparent;
    height: 0;
    position: absolute;
    width: 0;

    &--bottom-center {
      border-bottom: 6px solid currentColor;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      justify-self: center;
      top: -5px;
    }

    &--bottom-right {
      border-bottom: 6px solid currentColor;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      left: 8px;
      top: -5px;
    }

    &--bottom-left {
      border-bottom: 6px solid currentColor;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      right: 8px;
      top: -5px;
    }

    &--top-center {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid currentColor;
      bottom: -5px;
      justify-self: center;
    }

    &--top-right {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid currentColor;
      bottom: -5px;
      left: 8px;
    }

    &--top-left {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid currentColor;
      bottom: -5px;
      right: 8px;
    }

    &--left-center {
      align-self: center;
      border-bottom: 5px solid transparent;
      border-left: 6px solid currentColor;
      border-top: 5px solid transparent;
      right: -5px;
    }

    &--left-top {
      border-bottom: 5px solid transparent;
      border-left: 6px solid currentColor;
      border-top: 5px solid transparent;
      bottom: 8px;
      right: -5px;
    }

    &--left-bottom {
      border-bottom: 5px solid transparent;
      border-left: 6px solid currentColor;
      border-top: 5px solid transparent;
      right: -5px;
      top: 8px;
    }

    &--right-center {
      align-self: center;
      border-bottom: 5px solid transparent;
      border-right: 6px solid currentColor;
      border-top: 5px solid transparent;
      left: -5px;
    }

    &--right-top {
      border-bottom: 5px solid transparent;
      border-right: 6px solid currentColor;
      border-top: 5px solid transparent;
      bottom: 8px;
      left: -5px;
    }

    &--right-bottom {
      border-bottom: 5px solid transparent;
      border-right: 6px solid currentColor;
      border-top: 5px solid transparent;
      left: -5px;
      top: 8px;
    }
  }
}
