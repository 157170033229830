.au-code-input {
  display: flex;
  gap: 16px;

  &__input {
    height: 64px;
    width: 40px;

    background-color: var(--au-neutral-0);
    border-radius: 4px;
    border-style: none;
    color: var(--au-neutral-900);
    font-size: var(--au-size-16);
    font-weight: 400;
    line-height: var(--au-size-24);
    outline: var(--au-neutral-600) solid 1px;
    padding: 12px;
    text-align: center;

    &:focus {
      outline-width: 2.6px;
    }

    &.has-error {
      border-radius: 4px;
      outline: var(--au-error-600, #d12241) solid 1.2px;
    }
  }
}
