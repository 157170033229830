.au-card {
  background-color: var(--au-neutral-0);
  border-radius: 6px;
  display: inline-block;
}

.au-card-shadow {
  box-shadow: var(--au-shadow-100);

  &--elevated {
    border: 1px solid var(--au-neutral-300);
    box-shadow: var(--au-shadow-500);
  }
}

.au-card-border {
  border: 1px solid var(--au-neutral-300);
}

.au-card-border-no-bg {
  border: 1px solid var(--au-neutral-300);
  background-color: transparent;
}
