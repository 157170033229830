@use '../au-mixins/styles' as *;

.au-feedback {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: var(--au-neutral-0, #ffffff);
  border-radius: 6px;
  max-width: 1000px;
  width: 100%;
  padding: 48px 32px;

  @include sm {
    padding: 48px 48px;
  }

  &--no-bg {
    background-color: unset;
    box-shadow: unset;

    .au-feedback__bg-icon {
      background: var(--au-neutral-300, #dcdee2);
      color: var(--au-neutral-800, #535765);
    }
  }

  &__content {
    display: grid;
    place-items: center;
    gap: 32px;
    max-width: 624px;
    text-align: center;
    width: 100%;
  }

  &__bg-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px;
    height: 80px;
    padding: 20px;
    width: 80px;

    &--success {
      background: var(--au-success-100, #dcf9e7);
      color: var(--au-success-600, #207c4b);
    }

    &--warning {
      background: var(--au-warning-100, #fff9d3);
      color: var(--au-warning-600, #9e6a00);
    }

    &--error {
      background: var(--au-error-100, #fff0f1);
      color: var(--au-error-600, #d12241);
    }

    &--info {
      background: var(--au-primary-100, #f0f3ff);
      color: var(--au-primary-600, #2a53f6);
    }

    &--neutral {
      background: var(--au-neutral-100, #f6f6f7);
      color: var(--au-neutral-600, #85899b);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    flex-shrink: 0;
  }

  &__body {
    display: grid;
    color: var(--au-neutral-900, #32353e);
    gap: 16px;
  }

  &__footer {
    display: grid;
    gap: 8px;
  }
}
